export default [
  {
    name: 'Email',
    link: 'ali.nisar87@gmail.com',    
  },
  {
    name: 'linkedIn',
    link: 'https://www.linkedin.com/in/ali-ahmed-390483b8/',
  },
  {
    name: 'github',
    link: 'https://www.github.com/AliNisarAhmed',
  },
  {
    name: 'twitter',
    link: 'https://www.twitter.com/AliNisarAhmed',
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/Ali.AhmedNisar'
  }
]